import { TChildren } from '../../types/app';
import * as classNames from 'classnames';

const CardAuth = (props: TChildren) => {
  return (
    <div
      className={classNames(
        'w-full bg-white',
        'md:border-t-brand md:border-t-[5px] md:rounded-[5px] md:shadow-auth-card'
      )}
    >
      {props.children}
    </div>
  );
};

export default CardAuth;
