import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import AuthLayoutContext from '../../contexts/AuthLayoutContext';
import Layout from '../auth-layout/Layout';
import useUserSession from '../../hooks/useUserSession';
import withToastContainer from '../../hoc/withToastContainer';
import { TChildren } from '../../types/app';
import AuthLayoutSkeleton from '../skeletons/AuthLayoutSkeleton';

const Auth = (props: TChildren) => {
  const router = useRouter();
  const { _noRedirect } = router.query;
  const { user, loading } = useUserSession();

  useEffect(() => {
    if (user && !loading && !_noRedirect) {
      router.replace('/dashboard').then((_) => {});
    }
  }, [user, loading, _noRedirect]);

  const MyLayout = useMemo(
    () => withToastContainer<TChildren>(Layout, router.locale || 'en'),
    []
  );

  return (
    <AuthLayoutContext.Provider value={{}}>
      {(!user && !loading) || Boolean(_noRedirect) ? (
        <MyLayout>{props.children}</MyLayout>
      ) : (
        <AuthLayoutSkeleton />
      )}
    </AuthLayoutContext.Provider>
  );
};

export default Auth;
