import { ToastContainer } from 'react-toastify';
import React from 'react';

const withToastContainer = <T extends {}>(
  Component: React.ComponentType<T>,
  locale: string
) => {
  return function MyComponent(props: T) {
    return (
      <>
        <Component {...props} />
        <ToastContainer
          position={locale === 'ar' ? 'top-left' : 'top-right'}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={locale === 'ar'}
          pauseOnFocusLoss={true}
          draggable={false}
          pauseOnHover
          toastClassName={locale === 'ar' ? 'font-ar' : 'font-en'}
        />
      </>
    );
  };
};

export default withToastContainer;
