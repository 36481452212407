import { AppLogoArabic, AppLogoEnglish } from '../icons/AppLogo';
import Button from '../elements/Button';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';

const Header = () => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const AppLogo = () => {
    if (router.locale === 'ar') return <AppLogoArabic />;
    return <AppLogoEnglish />;
  };

  const goToRegister = () => {
    if (router.pathname === '/register') return;
    router.replace('/register');
  };

  const goToLogin = () => {
    if (router.pathname === '/login') return;
    router.replace('/login');
  };

  return (
    <div className="fixed min-h-[80px] md:h-[100px] overflow-hidden bg-white top-0 z-10 flex-shrink-0 flex shadow-md w-full px-[10px] sm:px-[24px] md:px-[34px]">
      <div className="flex-1 flex justify-center sm:justify-between items-center flex-wrap relative">
        <div className="flex-1 flex py-2">
          <span className="">
            <Link href="/">
              <a>
                <AppLogo />
              </a>
            </Link>
          </span>
        </div>

        <div className="flex items-center flex-wrap justify-between py-4 ltr:space-x-[20px] rtl:space-x-[10px] md:space-x-[40px] rtl:space-x-reverse">
          <Button
            link
            label={t('login')}
            color="secondary"
            loading={false}
            onClick={goToLogin}
          />
          <Button label={t('register')} onClick={goToRegister} />
        </div>
      </div>
    </div>
  );
};

export default Header;
