import Skeleton from 'react-loading-skeleton';

const AuthLayoutSkeleton = () => {
  return (
    <div className="h-screen flex flex-col justify-between">
      <div>
        <Skeleton className="w-full h-[100px] p-0 m-0" />
      </div>
      <div className="w-full max-w-[1140px] min-h-[523px] mx-auto px-6 md:px-12 rounded-md overflow-hidden">
        <Skeleton className="w-full h-full p-0 m-0" />
      </div>
      <div>
        <Skeleton className="w-full h-[100px] p-0 m-0" />
      </div>
    </div>
  );
};

export default AuthLayoutSkeleton;
