import { useSession } from 'next-auth/client';
import { useEffect, useState } from 'react';

const useUserSession = () => {
  const [session, loading] = useSession();
  const [user, setUser] = useState(session);
  const [loadingUser, setLoadingUser] = useState(loading);

  useEffect(() => {
    setUser(session);
    setLoadingUser(loading);
  }, [session, loading]);

  return {
    user: user?.user,
    loading: loadingUser,
  };
};
export default useUserSession;
