import { signIn, signout } from 'next-auth/client';
import {
  TLoginForm,
  TNewPasswordForm,
  TRegisterForm,
  TResetPasswordForm,
  TUserSession,
} from '../types/app';
import http, { setToken } from './http';

export const roles = ['owner', 'manager', 'company', 'tenant'];

const acceptJsonConfig = {
  headers: {
    Accept: 'application/json',
  },
};

// prettier-ignore
export const getToken = ({ email, ...rest }: TLoginForm): Promise<string> => {
  const URL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/core/token-auth/`;
  return new Promise<string>((resolve, reject) => {
    http.post(URL, { username: email, ...rest }, acceptJsonConfig).then((response) => {
      resolve(response?.data?.token || '');
    }).catch(reject);
  });
};

export const getUserInfo = (token: string): Promise<TUserSession> => {
  const userInfoURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/core/get-user-info/`;
  const config = {
    withCredentials: true,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${token}`,
    },
  };
  return new Promise<TUserSession>((resolve, reject) => {
    http
      .get(userInfoURL, config)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });
};

export const login = async (data: TLoginForm): Promise<TLoginForm> => {
  return new Promise<TLoginForm>(async (resolve, reject) => {
    try {
      const response = await signIn('credentials', {
        redirect: false,
        callbackUrl: '/login',
        ...data,
      });
      if (response?.error) reject(response?.error);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const resetPassword = (
  data: TResetPasswordForm
): Promise<TResetPasswordForm> => {
  const URL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/core/password-reset/`;
  return new Promise<TResetPasswordForm>((resolve, reject) => {
    http
      .post(URL, data, acceptJsonConfig)
      .then((response) => {
        resolve(response?.data?.token || '');
      })
      .catch(reject);
  });
};

export const newPassword = (
  data: TNewPasswordForm
): Promise<TNewPasswordForm> => {
  const URL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/core/password-reset/confirm/`;
  const body = {
    password: data.password,
    token: data.token,
  };

  return new Promise<TNewPasswordForm>((resolve, reject) => {
    http
      .post(URL, body, acceptJsonConfig)
      .then((response) => {
        resolve(response?.data?.token || '');
      })
      .catch(reject);
  });
};

export const logOut = () => {
  setToken();
  return signout({ redirect: false, callbackUrl: '/login' });
};

export const register = (data: TRegisterForm) => {
  const URL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/core/register-user/`;
  return http.post<{ Token: string }>(URL, data, acceptJsonConfig);
};
