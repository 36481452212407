import DashboardFooter from '../dashboard-layout/DashboardFooter';
import Header from './Header';
import SwitchLanguage from '../elements/SwitchLanguage';
import { useRouter } from 'next/router';
import { DEFAULT_LANGUAGE } from '../../lib/constants';
import { TChildren } from '../../types/app';

const Layout = ({ children }: TChildren) => {
  const router = useRouter();
  const switchLang = (nextLang: string) => {
    // prettier-ignore
    location.href = nextLang === DEFAULT_LANGUAGE ? router.asPath : `/${nextLang}${router.asPath}`;
    // TODO("Fix the issue of loading session always true when change language")
    // router
    //   .push({ pathname, query }, asPath, {
    //     locale: nextLang,
    //     shallow: true,
    //   })
    //   .then((_) => {});
    // prettier-ignore
    // document.getElementsByTagName("html")[0].setAttribute("dir", nextLang === "ar" ? "rtl" : "ltr");
    // document.getElementsByTagName("html")[0].setAttribute("lang", nextLang);
  };

  return (
    <div className="h-screen relative pt-[100px] no-scrollbar">
      <Header />
      <div className="h-full w-full flex flex-col justify-between pt-[68px] md:pt-[100px]">
        <div className="w-full max-w-[1140px] xl:w-[1140px] xl:px-0 px-[27px] md:px-[24px] mx-auto">
          {children}
        </div>
        <div className="w-full z-30">
          <div className="my-[40px]">
            <SwitchLanguage
              local={router.locale || DEFAULT_LANGUAGE}
              onClick={(local) => switchLang(local == 'en' ? 'ar' : 'en')}
            />
          </div>
          <DashboardFooter />
        </div>
      </div>
    </div>
  );
};

export default Layout;
